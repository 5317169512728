// import { EthereumProvider } from "@walletconnect/ethereum-provider";
// import WalletConnectProvider from "@walletconnect/web3-provider";

import mataSVG from './assets/metamask.svg';
import xdefiSVG from './assets/xdefi.svg';
import bitgetSVG from './assets/bitget.png';
import okxSVG from './assets/okx.svg';
import trustSVG from './assets/trust.svg';
import binanceSVG from './assets/binance.png';
import walletConnectSVG from './assets/wallet-connect.svg';
import icon from './assets/favicon.png';

import Onboard from '@web3-onboard/core'
import binanceModule from '@binance/w3w-blocknative-connector'

const wallets = [
    {
        name: 'MetaMask',
        keyName: 'metamask',
        provider: window.ethereum,
        available: !!window.ethereum,
        icon: mataSVG,
        homepage: 'https://metamask.io/download/'
    },
    {
        name: 'XDEFI',
        keyName: 'xfiWallet',
        provider: window.xfi && window.xfi.ethereum,
        available: !!(window.xfi && window.xfi.ethereum),
        icon: xdefiSVG,
        homepage: 'https://docs.xdefi.io/docs/products/browser-extension/browsers'
    },
    {
        name: 'Bitget',
        keyName: 'bitgetWallet',
        provider: window.bitkeep && window.bitkeep.ethereum,
        available: !!(window.bitkeep && window.bitkeep.ethereum),
        icon: bitgetSVG,
        homepage: 'https://web3.bitget.com/id/wallet-download?type=0'
    },
    {
        name: 'OKX',
        keyName: 'okxwallet',
        provider: window.okxwallet,
        available: !!(window.okxwallet),
        icon: okxSVG,
        homepage: 'https://chromewebstore.google.com/detail/%E6%AC%A7%E6%98%93-web3-%E9%92%B1%E5%8C%85/mcohilncbfahbmgdjkbpemcciiolgcge?hl=zh-CN&utm_source=ext_sidebar'
    },
    {
        name: 'Trust',
        keyName: 'trustwallet',
        provider: !!(window.trustWallet),
        icon: trustSVG,
        homepage: 'https://trustwallet.com/',
        docs: 'https://developer.trustwallet.com/developer/develop-for-trust/browser-extension/evm'
    },
    {
        name: 'Binance',
        keyName: 'binance',
        provider:  window.ethereum,
        available: (window.ethereum && window.ethereum.isBinance),
        icon: binanceSVG,
        homepage: 'https://www.binance.com/en/web3wallet',
        docs: 'https://www.binance.com/en/download'
    },
    {
        name: 'Binance',
        keyName: 'binanceQrcode',
        provider:  null,
        available: true,
        icon: binanceSVG,
        homepage: 'https://www.binance.com/en/web3wallet',
        docs: 'https://www.binance.com/en/download'
    }
    // ,{
    //     name: 'Wallet Connect',
    //     keyName: 'walletConnect',
    //     provider: null,
    //     available: true,
    //     icon: walletConnectSVG,
    //     homepage: 'https://docs.walletconnect.com/',
    //     docs: 'https://docs.walletconnect.com/'
    // }
];

const projectId = '1d263a1580cc053c400f6d2df6881611'; // REQUIRED your projectId
const name = 'League of Thrones';
const description = 'League of Thrones is a game played against real peers. You will act like a monarch with your own fortress, recruiting variety of generals with different skills. You need to develop yourself while watching alliances and enemies. They will attack you with no mercy. Season rewards will be distributed to the winning legion and individuals with the highest performance.';
const metadata =  {
    name,
    description,
    url: 'https://app.leagueofthrones.com/', // origin must match your domain & subdomain
    icons: ['https://cdn.leagueofthrones.com/favicon.png']
};

const walletImages = {
    "8a0ee50d1f22f6651afcae7eb4253e52a3310b90af5daef78a8c4929a9bb99d4": "https://explorer-api.walletconnect.com/v3/logo/sm/ebac7b39-688c-41e3-7912-a4fefba74600?projectId=1d263a1580cc053c400f6d2df6881611",
    "c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96": "https://explorer-api.walletconnect.com/v3/logo/sm/5195e9db-94d8-4579-6f11-ef553be95100?projectId=1d263a1580cc053c400f6d2df6881611",
    "38f5d18bd8522c244bdd70cb4a68e0e718865155811c043f052fb9f1c51de662": "https://explorer-api.walletconnect.com/v3/logo/sm/68e8063a-ff69-4941-3b40-af09e2fcd700?projectId=1d263a1580cc053c400f6d2df6881611",
    "971e689d0a5be527bac79629b4ee9b925e82208e5168b733496a09c0faed0709": "https://explorer-api.walletconnect.com/v3/logo/sm/45f2f08e-fc0c-4d62-3e63-404e72170500?projectId=1d263a1580cc053c400f6d2df6881611",
    "f896cbca30cd6dc414712d3d6fcc2f8f7d35d5bd30e3b1fc5d60cf6c8926f98f": "https://explorer-api.walletconnect.com/v3/logo/sm/efec6318-7f96-4b30-9287-6c287660cd00?projectId=1d263a1580cc053c400f6d2df6881611"
};

const desktopWallets =  [
    {
        "id": "8a0ee50d1f22f6651afcae7eb4253e52a3310b90af5daef78a8c4929a9bb99d4",
        "name": "Binance Web3 Wallet",
        "mobile": {
            "native": "bnc://app.binance.com/cedefi/",
            "universal": "https://app.binance.com/cedefi"
        },
        "links": {
            "native": "",
            "universal": null
        },
    },
    {
        "id": "c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96",
        "name": "MetaMask",
        "links": {
            "native": "",
            "universal": null
        }
    },
    {
        "id": "38f5d18bd8522c244bdd70cb4a68e0e718865155811c043f052fb9f1c51de662",
        "name": "Bitget Wallet",
        "links": {
            "native": "",
            "universal": null
        }
    },
    {
        "id": "971e689d0a5be527bac79629b4ee9b925e82208e5168b733496a09c0faed0709",
        "name": "OKX Wallet",
        "links": {
            "native": "",
            "universal": null
        }
    },
    {
        "id": "f896cbca30cd6dc414712d3d6fcc2f8f7d35d5bd30e3b1fc5d60cf6c8926f98f",
        "name": "XDEFI Wallet",
        "links": {
            "native": "",
            "universal": null
        }
    }
];

let onboardInstance = null;
async function getEvmProvider(wallet, chain){
    let keyName = wallet.keyName || '';

    let provider = wallet.provider;
    chain = chain || {}
    let chainId = (chain.chainId || 7000)/1 + ''; //zeta
    // if(keyName === 'walletConnect'){
    //     //https://docs.walletconnect.com/advanced/providers/ethereum
    //     provider = await EthereumProvider.init({
    //       projectId, // REQUIRED your projectId
    //       metadata,
    //       chains: [chainId], // REQUIRED chain ids
    //       showQrModal: true, // REQUIRED set to "true" to use @walletconnect/modal
    //     });
    //     await provider.connect();
    //     provider.type = 'walletConnect';
    // }
    if(keyName === 'binanceQrcode'){
        //https://developers.binance.com/docs/binance-w3w/quick-start#integration-with-blocknative
        //https://developers.binance.com/docs/binance-w3w/evm-compatible-provider#integration-with-blocknative
        const binance = binanceModule({options:{lng:'en'}})
        if(!onboardInstance){
            onboardInstance = Onboard({
                wallets: [binance],
                chains: [
                {
                    id: chain.chainId,
                    token: (chain.nativeCurrency || {}).symbol,
                    label: chain.name,
                    rpcUrl: chain.rpcUrls[0]
                }
                ],
                appMetadata: {
                    name,
                    icon,
                    description
                }
            });
        }

        document.querySelector('onboard-v2').style.all = '';
        const wallets = await onboardInstance.connectWallet({
            autoSelect: {
                label: 'binance',
                disableModals: true,
            }
        });
        console.log('onboard connectWallet', wallets, onboardInstance)
        if(wallets.length > 0){
            provider = wallets[0].provider;
            provider.type = 'binanceQrcode';
            console.log('binanceQrcode', wallets, provider)
        }
    }
    return provider;
}


export { wallets, getEvmProvider };
